import { ApiAreaOfFocus } from 'api/types/ApiAreaOfFocus';
import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { ApiJobFunction } from 'api/types/ApiJobFunction';
import { searchAlgoliaOptionsWithSearchLocation } from 'modules/search/algolia/options/searchAlgoliaOptionsWithSearchLocation';
import { searchAlgoliaConfig } from 'modules/search/algolia/searchAlgoliaConfig';
import { searchAlgoliaIndex } from 'modules/search/algolia/searchAlgoliaIndex';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { algoliaSearch } from 'utils/algolia/algoliaSearch';

type ResultItem = {
  objectID: string;
  name: string;
  city: string;
  state: string;
  logo: ApiFilestackImageData;
  orgName: string;
  url: string;
  earlyApplicant: boolean;
};

const ATTRIBUTES: (keyof ResultItem)[] = [
  'objectID',
  'name',
  'city',
  'state',
  'logo',
  'orgName',
  'url',
  'earlyApplicant',
];

type Props = {
  searchLocation: Partial<SearchLocation> &
    Pick<SearchLocation, 'latitude' | 'longitude'>;
  options: {
    onlyWithLogo: boolean;
    jobFunction?: ApiJobFunction | null | undefined;
    areaOfFocus?: ApiAreaOfFocus | null | undefined;
    jobTitle?: string;
    locale?: string;
    familyId?: string;
    query?: string;
  };
  searchOptions?: { distinct: boolean };
};

export async function searchAlgoliaFetchRecommendedJobs({
  searchLocation,
  options: {
    onlyWithLogo,
    jobFunction,
    areaOfFocus,
    locale,
    jobTitle,
    familyId,
  },
  searchOptions,
}: Props) {
  const filters = [
    onlyWithLogo && '_tags:hasLogo',
    jobFunction && `(functions:'${jobFunction}')`,
    areaOfFocus && `(areasOfFocus: '${areaOfFocus}')`,
    locale && `(locale: '${locale}')`,
    familyId && `(compdb.familyId:'${familyId}')`,
  ]
    .filter(Boolean)
    .join(' AND ');

  return algoliaSearch<ResultItem>({
    config: searchAlgoliaConfig(),
    query: '',
    options: searchAlgoliaOptionsWithSearchLocation({
      options: {
        index: searchAlgoliaIndex('newest'),
        hitsPerPage: 20,
        attributesToRetrieve: ATTRIBUTES as string[],
        facetFilters: [['type:JOB']],
        filters,
        query: jobTitle,
        ...searchOptions,
      },
      searchLocation,
      radius: 'auto',
    }),
  }).then(({ hits }) => hits);
}
