import { CmsApiRecommendedJobsBlockData } from 'modules/cms/api/types/blocks/CmsApiRecommendedJobsBlock';

import { CmsStandardRecommendedJobs } from './Standard/CmsStandardRecommendedJobs';

type Props = {
  data: CmsApiRecommendedJobsBlockData;
  source: 'RECOMMENDED_JOBS_CHECKBOX' | 'RECOMMENDED_JOBS_BLOCK';
};

export function CmsRecommendedJobsBlockContent({
  data: { headline, layout, jobFunction, areaOfFocus, locale },
  source,
}: Props) {
  return (
    <CmsStandardRecommendedJobs
      headline={headline}
      layout={layout}
      jobFunction={jobFunction}
      areaOfFocus={areaOfFocus}
      locale={locale}
      source={source}
    />
  );
}
