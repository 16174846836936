import { Box } from 'components/Box/Box';
import { CmsApiRecommendedJobsBlockData } from 'modules/cms/api/types/blocks/CmsApiRecommendedJobsBlock';
import { colors, gutterWidth } from 'theme/theme';

import { CmsRecommendedJobsBlockContent } from './CmsRecommendedJobsBlockContent';

type Props = {
  data: CmsApiRecommendedJobsBlockData;
  fromCheckbox?: boolean;
};

export function CmsRecommendedJobsBlock({ data, fromCheckbox }: Props) {
  const { layout, jobFunction, areaOfFocus } = data;

  const source = fromCheckbox
    ? 'RECOMMENDED_JOBS_CHECKBOX'
    : 'RECOMMENDED_JOBS_BLOCK';

  if (layout === 'SLIM') {
    // Additional gutter to position the arrows within the box
    const additionalXGutter = 76;

    return (
      <Box
        mx={[-gutterWidth / 2, null, -gutterWidth]}
        py={[gutterWidth / 2, null, gutterWidth]}
        px={[
          gutterWidth / 2,
          gutterWidth,
          gutterWidth + additionalXGutter,
          gutterWidth + additionalXGutter,
        ]}
        bg={colors.inputGrey}
        data-qa-id="recommended-jobs"
        data-qa-layout={layout}
        data-qa-source={source}
        data-qa-job-function={jobFunction}
        data-qa-area-of-focus={areaOfFocus}
      >
        <CmsRecommendedJobsBlockContent data={data} source={source} />
      </Box>
    );
  }

  return (
    <div
      data-qa-id="recommended-jobs"
      data-qa-layout={layout}
      data-qa-source={source}
      data-qa-job-function={jobFunction}
      data-qa-area-of-focus={areaOfFocus}
    >
      <CmsRecommendedJobsBlockContent data={data} source={source} />
    </div>
  );
}
