import { useEffect, useState } from 'react';

import { ApiAreaOfFocus } from 'api/types/ApiAreaOfFocus';
import { ApiJobFunction } from 'api/types/ApiJobFunction';
import { searchAlgoliaFetchRecommendedJobs } from 'modules/search/algolia/fetch/searchAlgoliaFetchRecommendedJobs';
import { SearchAlgoliaRecommendedListing } from 'modules/search/algolia/types/SearchAlgoliaRecommendedListing';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { log } from 'utils/logging';

/*
 * Fetch recommended jobs
 */
export function useSearchAlgoliaRecommendedJobs(
  searchLocation: Partial<SearchLocation> &
    Pick<SearchLocation, 'latitude' | 'longitude'>,
  options?: {
    onlyWithLogo: boolean;
    jobFunction?: ApiJobFunction | null;
    areaOfFocus?: ApiAreaOfFocus | null;
    jobTitle?: string;
    familyId?: string;
    locale?: string;
    query?: string;
  },
) {
  // @ts-expect-error TS(2339): Property 'onlyWithLogo' does not exist on type '{ ... Remove this comment to see the full error message
  const { onlyWithLogo, jobFunction, areaOfFocus, locale, familyId } = options;

  const [data, setData] = useState<SearchAlgoliaRecommendedListing[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    searchAlgoliaFetchRecommendedJobs({
      searchLocation,
      options: {
        onlyWithLogo,
        jobFunction,
        areaOfFocus,
        locale,
        familyId,
      },
      searchOptions: { distinct: true },
    })
      .then((jobs) => {
        setData(
          jobs.map((job) => ({
            city: job.city,
            id: job.objectID,
            logo: job.logo,
            orgName: job.orgName,
            name: job.name,
            state: job.state,
            url: job.url,
          })),
        );
      })
      .catch((e) => {
        setError(e);
        log.error(e);
      })
      .finally(() => setIsLoading(false));
  }, [
    searchLocation,
    onlyWithLogo,
    jobFunction,
    areaOfFocus,
    locale,
    familyId,
  ]);

  return { data, isLoading, error };
}
